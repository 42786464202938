@import '../../assets/colors.scss';

.contactContainer {
	min-height: 83vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.titleContainer {
	min-height: 35vh;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.buttonsWrapper {
	min-height: 50vh;
	width: 85%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.titleRow {
	width: 90%;
	display: flex;
	box-sizing: border-box;
	padding-bottom: 2rem;
	border-bottom: 1px solid $primary_100;
}

.title {
	font-weight: 900;
	font-size: calc(35px + 3vw);
	color: $white;
}

.buttonGrid {
	min-width: 285px;
	height: 60%;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(4, 1fr);
	justify-content: center;
	grid-auto-rows: auto;
	gap: 5rem;
	width: 100%;
	box-sizing: border-box;
}

.contactButton,
.contactMailButton {
	height: 45%;
	border: 1px solid $primary_100;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;
	&:hover {
		color: $black_100;
		background: $primary_100;
	}
}

.contactMailButton {
	font-weight: 300;
	color: $white;
	&:hover {
		font-weight: 500;
		cursor: pointer;
	}
}

.contactLink {
	height: 100%;
	width: 100%;
	text-decoration: none;
	font-weight: 300;
	font-size: calc(12px + 0.5vw);
	color: $white;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	&:hover {
		font-weight: 500;
		color: $black_100;
	}
}

.contactMail {
	word-break: break-word;
	font-size: calc(10px + 0.4vw);
}

.copied {
	position: absolute;
	top: 88%;
	left: 2%;
	padding: 1rem 1.25rem;
	font-weight: 500;
	border-radius: 5px;
	background: $white;
	color: $black_100;
	animation: fade 1.5s ease-in-out;
	animation-fill-mode: forwards;
	opacity: 0;
	box-shadow: 0 3px $gray_200;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media screen and (max-width: 1050px) {
	.titleContainer {
		height: 35%;
	}
	.buttonsWrapper {
		width: 90%;
		min-height: 50vh;
	}
	.buttonGrid {
		grid-template-columns: repeat(2, 40%);
		grid-auto-rows: 1fr;
		gap: 2rem 3rem;
	}
	.contactButton,
	.contactMailButton {
		height: 90%;
	}
	.titleRow {
		padding-bottom: 2rem;
	}
	.copied {
		top: 8%;
		left: auto;
	}
}

@media screen and (max-width: 600px) {
	.titleContainer {
		height: 40%;
	}
	.buttonsWrapper {
		min-width: 85%;
		height: 100%;
	}
	.buttonGrid {
		height: 85%;
		width: 100%;
		grid-template-columns: repeat(1, 80%);
		grid-auto-rows: 1fr;
		box-sizing: border-box;
		gap: 0;
	}
	.titleRow {
		padding-bottom: 0.5rem;
	}
}
