@import '../../assets/colors.scss';

$size1: 1150px;
$size2: 600px;
$lineWidth: 85%;

.navbarContainer {
    height: 100%;
    display: flex;
    top: 0;
    z-index: 10;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.navbarRow {
    display: flex;
    align-items: center;
    // min-height: 46px;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
}

.navbarCol {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: center;
}

@media screen and (max-width: $size1) {
    .navbarCol {
        width: 50%;
    }
}
@media screen and (max-width: $size2) {
    .navbarContainer {
        display: none;
    }
}

.navLink {
    padding: 0;
    font-size:calc(16px + 0.6vw);
    font-weight: 400;
    // padding: .5rem 1rem;
    box-sizing: border-box;
    transition: all .2s ease-in-out;
}

.navLink {
    color: $white;
    opacity: .5;
    hr {
        border: 0;
        border-top: 1px solid $primary_100;
        width: $lineWidth;
        position: relative;
        top: 10%;
        left: 0;
        margin: .05rem auto;
        transition: width .5s ease-in-out;
        -webkit-transition: width .5s ease-in-out;
        animation: close .3s ease-in-out forwards;
        -webkit-animation: close .3s ease-in-out forwards;
    }
    &:hover hr {
        animation: open .3s ease-in-out forwards;
        -webkit-animation: open .3s ease-in-out forwards;
    }
}

.navBarActiveLink {
    color: $white;
    opacity: .8;
    hr {
        border: 0;
        border-top: 1px solid $primary_100;
        width: $lineWidth;
        position: relative;
        top: 10%;
        left: 0;
        margin: .05rem auto;
        animation: none;
    }
    &:hover hr {
        animation: none;
    }
}

.navText {
    letter-spacing: 0.02em;
    font-size: calc(10px + 1vw);
}

@keyframes open {
    0% {
        width: 0;
    }
    100% { 
        width: $lineWidth;
        right: 0;
    }
}

@keyframes close {
    0% {
        width: $lineWidth;
        right:0;
    }
    100% { 
        width: 0; 
    }
}