//Light Theme
$bg_light:         #FCFCFC; //Background color
$text_light:       #2D3331; //Text color

//Dark Theme
$bg_dark:          #181818; //Background color
$text_dark:        #f0f0f0; //Text color

//Grayscale

$white:       #fafafa;
$gray_100:    #2D2D2D;
$gray_200:    #a8a8a8;
$gray_300:    #161616;
$gray_400:    #161616;
$black_100:   #252525;
$black_200:   #202020;

//Palette colors

$primary_100: #3ee0c5;
$primary_alt: #61ffe5;
$primary_200: darken($primary_100, 40%);
$primary_300: lighten($primary_100, 20%);
$primary_400: darken($primary_100, 20%);

$complementary_100: #d5fffd;
$complementary_200: #707070;

$accent_100: #f14c91; //Github Repo Color Button
$accent_200: #fab34c; //Live Site Color Button