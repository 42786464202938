.navMenuRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-top: .05rem solid #3d3d3d;
    background: #181818;
    z-index: 9999;
    flex: 1;
    margin-top: auto;
    display: none;
}

@media screen and (max-width: 600px) {
    .navMenuRow {
        display: flex;
    }
}

.navMenuCol {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navMenuLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    font-weight: 100;
    color: #fafafa;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
}

.activeLink {
    // font-weight: 500;
    color: #3ee0c5;
    opacity: 1;
}

.navMenuText {
    font-size:calc(12px + 0.4vw);
    margin-top: .3rem;
    transition: all .3s ease-in-out;
}