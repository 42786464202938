.about {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__TEMPORALTITLE {
    text-align: center;
}