@import '../../assets/colors.scss';

.home {
    display: flex;
    align-items: center;
    min-height: 100%;
    width: 100%;
    min-width: 310px;
    flex-direction: column;
}

.nameRow {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.nameCol {
    width: 50%;
    margin-left: 5rem;
    transition: width 1s ease-in-out;
}

.jobRow {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.jobCol {
    width: 55%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.name {
    font-weight: 900;
    letter-spacing: 0.01em;
    font-size: calc(40px + 3.5vw);
    text-align: left;
    margin: 0;
}

.job {
    font-weight: 300;
    letter-spacing: 0.02em;
    font-size: calc(12px + 2vw);
    text-align: center;
    margin: 0;
    padding: calc(10px + 1.5vw) calc(12px + 3vw);
    padding-right: calc(12px + 3.5vw);
    border-radius: 5px 0 0 5px;
    color: $black_100;
    background-color: $primary_alt;
    transition: all .2s ease-in-out;
}

@media screen and (max-width: 1000px) {
    .nameCol {
        margin-left: 3rem;
    }
    .jobRow {
        height: 25%;
    }
    .jobCol {
        width: auto;
        height: 50%;
    }
    .job {
        padding: 1.5rem 1.5rem;
        padding-right: 2.5rem;
    }
}

@media screen and (max-width: 600px) {
    .nameCol {
        margin-left: 1rem;
    }
    .jobCol {
        width: auto;
        height: 40%;
    }
    .job {
        padding: 1.2rem 1rem;
        padding-right: 1.5rem;
    }
}